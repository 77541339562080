.arial{
    font-family: Arial, sans-serif;
}
.tbl-biaya{
    border: 1px solid #000000;
    border-collapse: collapse;
}
.tbl-biaya > tbody > tr > td{
    border: 1px solid #000000;
    border-collapse: collapse;
    padding: 2px;
    padding-left: 5px;

}
.tbl-biaya > thead > tr > th{
    border: 1px solid #000000;
    border-collapse: collapse;
    padding: 2px;
    padding-left: 5px;
    text-align: center;
}
.tbl-biaya > tfoot > tr > td{
    border: 1px solid #000000;
    border-collapse: collapse;
    padding: 2px;
    padding-left: 5px;

}