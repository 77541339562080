.arial{
    font-family: Arial, Helvetica, sans-serif;
}
.header-invoice{
    background-color: rgb(64, 64, 64);
    color:#ffffff;
    padding-left: 10px;
   padding-top: 0.1px;
    
}
.ppsdm-kop-invoice{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.table-tagih{
    font-size: 13px;
    margin-top: 10px;
}

.ppsdm-2nd{
    margin-top: 20px;
    padding-bottom: 10px;
    font-size: 18px;
    font-style: italic;
    font-weight: bold;
    font-family: Calibri, sans-serif;
}
.calibri{
    font-family: Calibri, sans-serif;

}
.second-header-invoice{
    background-color: #cccccc;
}
.invoice-txt{
    background-color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 150px;
}
.table-invoice{
    border-collapse: collapse;
    font-size: 13px;
    margin-top: 15px;
}
.table-invoice > tbody > tr > td {
    border: 1px solid black;
    border-collapse: collapse;
    padding-left: 5px;
    padding-right: 5px;
   
}
.table-invoice > thead > tr > th {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;

}
.table-invoice > tfoot > tr > td {
    border: 1px solid black;
    border-collapse: collapse;
    padding-left: 5px;
    padding-right: 5px;
   

}