@font-face {
    font-family: 'Kartika';
    src: url('./kartika.ttf');
      
    }
    .kartika{
        font-family:'Kartika';
    }
    .arial{
        font-family: Arial, Helvetica, sans-serif;
    }
    .file{
        width: 210mm;
        min-height:270mm;
        /* background-color: #f0f0f0; */
    }
    .center{
        text-align: center;
    }
    /* .{
        font-size: 10px;
    } */
    .text-md{
        font-size: 15px;
    }
    .text-lg{
        font-size: 22px;
    }
    .line-h{
        line-height: 20px;
    }
    .text-nm{
        font-size: 14px;
    }
    .margin{
        margin: 5px 50px 0px 50px
    }
    .justify{
        text-align: justify;
    }
    .kop-ttd{
        margin-left: 350px;
    }
    .courier{
        font-family: 'Courier New', Courier, monospace;
    }