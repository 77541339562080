/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;800&family=Roboto:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@300&display=swap');
.bg-penerimaan{
  background-image: url('./bg1.svg');
  background-size: cover;
}
.bg-pengeluaran{
  background-image: url('./bg2.svg');
  background-size: cover;
}
.App {
  text-align: center;
}
.loginPem{
  padding-left: 36%;
}
.loginPen{
  padding-left: 36%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.icon-dashboard{
  width:60px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media print {
  footer {page-break-after: always;}
 
         @page {
         /* size: 8.560cm  5.398cm; */
         /* margin-top: 1cm;
         margin-bottom: 0;
         margin-left: 0;
         margin-right: 0;
          */
         }
}
.animation-lr{
  animation: lr;
  animation-duration: 0.9s;
  /* animation-iteration-count: infinite; */
  animation-timing-function: ease-in;
  background-color: transparent;
}
.animation-rl{
  animation: rl;
  animation-duration: 0.9s;
  /* animation-iteration-count: infinite; */
  animation-timing-function: ease-in-out;
  background-color: transparent;
}


.r-login{
  left:67%;
}

@keyframes lr {
  0%   {left:0px;width:33%;}
  
  50%  {width:70%;}
 
  100% {left:67%;width:33%;}
}
@keyframes rl {
  0%   {left:67%;}
  50%  {width:70%;}
  
 
  100% {left:0%;}
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#target-hover{
  display: none;
}
#profil-hover:hover #target-hover  {
  display: block;
}